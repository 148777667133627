import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Grid, Alert } from "@mui/material";
import { Helmet } from "react-helmet";
import _ from "lodash";
import { useHistory } from "react-router-dom";

import { context } from "./PublicHomeContext";
import { context as quizContext, actions } from "../Quiz";
import { context as authContext } from "../AuthContext";

import Welcome from "./Welcome";
import HtmlMessage from "./HtmlMessage";
import Contest from "./Contest";
import Leaderboard from "./Leaderboard";
import SalesMessage from "./SalesMessage";
import Tips from "./Tips";
import Quizzes from "./Quizzes";
import NewsFeed from "./NewsFeed";
import Q2GoButton from "./Q2GoButton";
import JumpToQuizzes from "./JumpToQuizzes";
import AppStoreDownload from "./AppStoreDownload";
import LoginController from "../LoginController";
import isIE from "../../utils/isIE";
import Deactivated from "../Deactivated";

const PublicHome = () => {
  const history = useHistory();

  const { state } = useContext(context);
  const { state: authState } = useContext(authContext);
  const [appStoreDownloadOpen, setAppStoreDownloadOpen] = useState(false);

  const needsLogin = !authState.isLoggedIn;

  const {
    programMessage,
    contestMessage,
    newsFeed,
    notice,
    contest,
    deactivated_message,
    id: companyId,
  } = state;

  // Shitty customizations
  let contestTitle = "Contest";
  if (companyId === 2851) {
    contestTitle = "Incentive Requirements";
  }

  const { dispatch } = useContext(quizContext);

  const containerRef = useRef();
  const quizzesSectionRef = useRef();

  const shouldOpenDownload =
    history.location.pathname.includes("togo-download");

  const shouldShowLogin = history.location.search.includes("login=true");

  useEffect(() => {
    if (shouldOpenDownload) {
      setAppStoreDownloadOpen(true);
    }
  }, [shouldOpenDownload, setAppStoreDownloadOpen]);

  useEffect(() => {
    // Reset quiz state when you visit home page to not pollute other quizzes / repeats
    dispatch({ type: actions.reset });
  }, [dispatch]);

  if (deactivated_message) {
    return <Deactivated message={deactivated_message} />;
  }

  const showContest = contestMessage || contest;

  const includeNewsFeed = newsFeed && newsFeed.length > 0;
  const hasFirstColumn = programMessage || showContest || includeNewsFeed;
  const hasRightColumn = true;

  const getNumColumns = () => {
    let count = 1;
    if (hasFirstColumn) count++;
    if (hasRightColumn) count++;

    return count;
  };

  const numColumns = getNumColumns();

  const getWidthFromNumColumns = () => {
    switch (numColumns) {
      case 3:
        return "xl";
      case 2:
        return "lg";
      case 1:
        return "md";
      default:
        return "md";
    }
  };

  const width = getWidthFromNumColumns();

  const getPrimaryParams = () => {
    if (numColumns === 1) {
      return {
        sm: 12,
      };
    }

    if (numColumns === 2) {
      return {
        sm: 12,
        md: 7.5,
        xl: 4,
      };
    }

    return {
      sm: 12,
      md: 5,
      xl: 4,
    };
  };

  const getSecondaryParams = () => {
    if (numColumns === 1) {
      return {
        sm: 12,
      };
    }

    if (numColumns === 2) {
      return {
        sm: 12,
        md: 4.5,
        xl: 4,
      };
    }

    return { xs: 12, sm: 12, md: 3.5, xl: 4 };
  };

  const primaryParams = getPrimaryParams();
  const secondaryParams = getSecondaryParams();

  const renderRightColumn = () => (
    <Q2GoButton
      togoLink={state.togoLink}
      companyId={state.id}
      needsLogin={needsLogin}
      appStoreDownloadOpen={appStoreDownloadOpen}
      setAppStoreDownloadOpen={setAppStoreDownloadOpen}
    />
  );

  if (shouldShowLogin) {
    return <LoginController companyId={state.id} />;
  }

  if (appStoreDownloadOpen) {
    let togoAuthToken;

    try {
      const { togoLink } = state;
      if (togoLink) {
        togoAuthToken = _.last(_.last(togoLink.split("?")).split("="));
      }
    } catch (err) {
      console.error(err);
    }

    return (
      <>
        <AppStoreDownload
          handleClose={() => {
            setAppStoreDownloadOpen();
          }}
          togoAuthToken={togoAuthToken}
        />
      </>
    );
  }

  return (
    <div
      id="page-container"
      style={{
        height: "100%",
        overflowY: "auto",
      }}
    >
      <SalesMessage {...state} />
      <Container
        maxWidth={width}
        ref={containerRef}
        sx={{
          paddingTop: { sm: 2, md: 4 },
          paddingBottom: 5,
        }}
      >
        <Helmet>
          <meta httpEquiv="Cache-Control" content="no-cache" />
          <meta httpEquiv="Pragma" content="no-cache" />
          <meta httpEquiv="Expires" content="0" />
        </Helmet>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Welcome {...state} />
          </Grid>
          {notice ? (
            <Grid item sm={12}>
              <HtmlMessage html={notice} title="Notice" type="notice" />
            </Grid>
          ) : null}
          {/* {isIE ? null : (
            <Grid
              item
              sm={12}
              sx={{
                maxWidth: "100%",
                display: { xs: "none", sm: "none", md: "inherit" },
              }}
            >
              <Tips {...state} />
            </Grid>
          )} */}
          <JumpToQuizzes quizzesSectionRef={quizzesSectionRef} />
          {hasFirstColumn && (
            <Grid item {...secondaryParams}>
              <Grid container spacing={2}>
                {programMessage ? (
                  <Grid item sm={12}>
                    <HtmlMessage
                      html={programMessage}
                      title="Program Message"
                      type="program"
                    />
                  </Grid>
                ) : null}
                {contestMessage || contest ? (
                  <Grid item sm={12}>
                    <Contest
                      message={contestMessage}
                      contest={contest}
                      title={contestTitle}
                    />
                  </Grid>
                ) : null}

                <Leaderboard companyId={state.id} />

                {includeNewsFeed && (
                  <Grid item xs={12}>
                    <NewsFeed {...state} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item {...primaryParams} ref={quizzesSectionRef}>
            <Quizzes {...state} />
          </Grid>
          {hasRightColumn && (
            <>
              <Grid
                item
                {...secondaryParams}
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              >
                {renderRightColumn()}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default PublicHome;
