import React, { useContext } from "react";
import { context as authContext } from "../AuthContext";
import { CircularProgress, Container, Typography, Stack } from "@mui/material";
import queryString from "query-string";
import WalletPasses from "./WalletPasses";
import GetTheSticker from "./GetTheSticker";

const Togo = () => {
  const { state } = useContext(authContext);
  const { api_key, variant, os_options, coordinates, embed, mobileWebview } =
    queryString.parse(window.location.search);

  const userCoordinates = coordinates?.split(",").map((c) => parseFloat(c));

  const osOptions = os_options?.split(",");

  if (!state.isLoggedIn && !api_key) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <div
      style={{ height: "100%", overflowY: "auto", backgroundColor: "#efefef" }}
    >
      <Container maxWidth="sm" sx={{ pb: 10 }}>
        <Stack sx={{ mt: 5 }} spacing={3}>
          {embed === "true" && (
            <Typography
              align="center"
              color="primary"
              sx={{
                fontWeight: "900 !important",
                fontFamily: "Didot !important",
                fontSize: "3.0em",
              }}
            >
              Quizzify
            </Typography>
          )}
          <Typography variant="h1" textAlign="center">
            Take our <em>ER Prevent Consent</em> with you to keep (almost all)
            ER bills under $1,000*.
          </Typography>

          <Typography textAlign="center">
            * Routine ER visits will be &lt;$1000. Procedures, outside consults,
            and scans will cost more but the savings will be even greater.
          </Typography>
          <WalletPasses
            osOptions={osOptions}
            userCoordinates={userCoordinates}
            mobileWebview={mobileWebview}
          />
          {variant !== "wallet-card-only" && <GetTheSticker />}
        </Stack>
      </Container>
    </div>
  );
};

export default Togo;
